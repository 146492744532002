<template>
  <div class="animated fadeIn">
    <table-custom
      ref="dataTable"
      :name="`${$customTable.getCustomTableName($route.meta.module)}`"
      :loading="dataTable.isLoading"
      :data="dataTable.dataSet"
      :options="dataTable.options"
    >
    </table-custom>
  </div>
</template>

<script>
export default {
  name: "BarrelsTable",
  props: {
    barrelNumber: {
      type: [String, Number],
      default: ""
    }
  },
  components: {},
  data: function() {
    return {
      rawData: {},
      appliedFilters: [],
      dataTable: {
        visible: true,
        isLoading: false,
        options: {
          uniqueKey: "ID",
          showChildRowToggler: false,
          filterByColumn: true,
          columns: [
            "ID",
            "Barrel Number",
            "Warehouse Number",
            "Street",
            "City",
            "Zip",
            "Currency",
            "Hours",
            "Created By",
            "Modified By",
            "Created",
            "Modified"
          ],
          perPage: 50,
          disablePerPageDropdown: false,
          perPageValues: []
        },

        dataSet: [],
        onRowClick: function() {},
        totalRecords: 0
      }
    };
  },
  computed: {},
  mounted() {
    if (this.barrelNumber) this.getData({ barrel_number: this.barrelNumber });
  },
  methods: {
    async getData(payload) {
      let self = this;

      this.selectedFilters = payload || {};

      this.dataTable.isLoading = true;

      let response = [];

      try {
        console.log("getData", payload);
        response = await this.$api.post("barrels-location", payload);
        console.log("response", response);
        self.dataTable.isLoading = false;

        self.dataTable.dataSet = response;

        if (response.length === 0) return;

        self.$emit("loaded", self.dataTable.dataSet.length);
      } catch (error) {
        console.log(error);
        self.dataTable.isLoading = false;
        self.$form.msgBoxOk("Error occured");
      }
    }
  }
};
</script>

<style scoped></style>
